import React from 'react';
import { useState } from 'react';
import './App.css';
import { ZoomMtg } from '@zoomus/websdk';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.14.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {
  const [role, setRole] = useState(1);
  var authEndpoint = '//retrocubedevs.com:5247/api/generate_metting'
  //var authEndpoint = 'http://127.0.0.1:3333'
  var sdkKey = 'KjpPM9gLTiaZ6RWEi5_Tw'
  var meetingNumber = '85644936645'
  var passWord = ''
  //var role = 1
  var userName = 'Herry'
  var userEmail = 'herry@yopmail.com'
  var registrantToken = ''
  var zakToken = ''
  var leaveUrl = '//zoom.qa.retrocubedev.com'

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  function getSignature(e) {
    e.preventDefault();
    
    fetch(authEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      console.log('base then-------------->',response)
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      <main>
        <h1>Zoom Meeting</h1>
        <input type='text' name='role' id='role' placeholder='Role' onChange={handleChange}></input><br></br>
        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>
  );
}

export default App;
